import { useCallback } from 'react'
import {
  logEvent as firebaseLogEvent,
  AnalyticsCallOptions,
  Analytics,
} from 'firebase/analytics'
import { useNotification } from 'app/provider/notification-context'
import { Platform } from 'react-native'
import { useSelector } from 'react-redux'
import { makeUseAxiosHook } from 'app/redux/store'

interface DefaultEventParams {
  timestamp: string
  [key: string]: string | number | boolean
}

interface LogEventParams {
  eventName: string
  isAnalytics?: boolean;
  isCrashlytics?:boolean;
  additionalParams?: Record<string, any>
}

type LogEventFunction = (params: LogEventParams) => void

const useFirebaseEvents = (): LogEventFunction => {
  const { firebaseAnalytics } = useNotification()
  const { user } = useSelector((state: any) => ({ user: state.user }))

  const [{ }, updateAnalytics] = makeUseAxiosHook('default', {
    manual: true,
    autoCancel:false
  })({
    url: '/api/v1/user-analytics',
    method: 'POST',
  })

  const logEvent: LogEventFunction = useCallback(
    ({ eventName, isAnalytics = true, additionalParams = {},isCrashlytics=false }) => {
      const defaultParams: DefaultEventParams = {
        timestamp: new Date().toISOString(),
        userName: user?.name,
        userId: user?._id,
        deviceType: Platform.OS,
        // Add any other default parameters you want to include with every event
      }

      const eventParams: Record<string, any> = {
        ...defaultParams,
        ...additionalParams,
      }

      if (isAnalytics && typeof window !== 'undefined') {
        // Create a new object that matches AnalyticsCallOptions
        const analyticsParams: AnalyticsCallOptions = {
          global: false, // or true, depending on your needs
          ...eventParams,
        }

        firebaseLogEvent(
          firebaseAnalytics as Analytics,
          eventName,
          analyticsParams
        )
      }

      if (isCrashlytics) {
        firebaseLogEvent(firebaseAnalytics as Analytics,'error', eventParams)
      }
      console.log('isAnalytics',isAnalytics);
      
      if(isAnalytics || isCrashlytics){
        updateAnalytics({
          data:{
            ...defaultParams,
            type:isCrashlytics?'error':'event',
            eventName:eventName,
            eventDetails:{...additionalParams}
          }
        }).then((res)=>{
          console.log('res',res);
          
        }).catch((error)=>{
          console.log('error',error);
          
        })
      }

      // Log to console in development environment
      if (process.env.NODE_ENV === 'development') {
        console.log(`[Firebase Event] ${eventName}`, eventParams)
      }
    },
    [firebaseAnalytics]
  )

  return logEvent
}

export default useFirebaseEvents
